.container {
  position: relative;
  width: var(--size);
  height: var(--size);
}

.overdrawCircle {
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #ffffff57;
  backdrop-filter: blur(15px);
  box-shadow: inset 0 0 0.3rem #ababab3d;
  z-index: 10;
}

.orbitingCircles {
  position: absolute;
  overflow: hidden;
  width: var(--size);
  height: var(--size);
  border-radius: 100%;
  animation: spinning 10s ease-in-out infinite;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.orbitingCircles > div {
  position: absolute;
  top: 40%;
  left: 40%;
  border-radius: 50%;
}

.orbitingCircles > div:nth-child(1) {
  width: 35%;
  height: 35%;
  animation: orbit1 4s ease-in-out infinite;
  background-color: rgba(251, 0, 255, 0.9);
}
.orbitingCircles > div:nth-child(2) {
  width: 35%;
  height: 35%;
  animation: orbit2 3s ease-in-out infinite;
  background-color: rgba(4, 255, 0, 0.9);
}
.orbitingCircles > div:nth-child(3) {
  width: 35%;
  height: 35%;
  animation: orbit3 5s ease-in-out infinite;
  background-color: rgba(0, 255, 213, 0.9);
}

@keyframes orbit1 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  20% {
    transform: translate(0, -30%) scale(1.5);
  }
  40% {
    transform: translate(30%, -30%);
    opacity: 0.5;
  }
  60% {
    transform: translate(-50%, 70%) scale(0.5);
  }
  80% {
    transform: translate(0, -60%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes orbit2 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0, -100%);
    opacity: 1;
  }
  40% {
    transform: translate(-70%, 70%) scale(0.5);
  }
  60% {
    transform: translate(70%, -30%);
    opacity: 0.7;
  }
  80% {
    transform: translate(-70%, -10%) scale(1.5);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes orbit3 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  20% {
    transform: translate(0, -80%);
  }
  40% {
    transform: translate(-100%, 80%) scale(0.5);
    opacity: 0.5;
  }
  60% {
    transform: translate(100%, -30%);
  }
  80% {
    transform: translate(-70%, -10%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}
